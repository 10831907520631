import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: (theme) => ({
    [theme.mediaRequests.notMobile]: {
      width: 400,
    },
  }),

  actions: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",

    "& button": {
      width: "calc(50% - 16px)",
    },
  },
});
