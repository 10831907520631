import React from "react";
import { useLocation, useHistory } from "react-router-dom";

import Dialog from "@ui-kit/Dialog";
import Typography from "@ui-kit/Typography";
import { createCss } from "./styles";
import Button from "@ui-kit/Button";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";
import { Spacer } from "@ui-kit/Spacer";
import { Models } from "@services/api";

export const ExistedWarning: React.FC<{
  onClose: () => void;
  inquiryId: number;
}> = ({ onClose, inquiryId }) => {
  const history = useHistory();
  const { search } = useLocation();
  const [{ facility }] = useDeepDivePanelStore();
  const css = createCss();

  return (
    <Dialog open onClose={onClose} tier={facility.subscriptionTier}>
      <div css={css.root}>
        <Typography align="center" variant="h5" bolded>
          You have already contacted this school
        </Typography>
        <Spacer size="medium" />
        <Button
          variant="contained"
          color={
            facility.subscriptionTier === Models.SubscriptionTier.Ivy
              ? "secondary"
              : "black"
          }
          fullWidth
          size="large"
          onClick={() => {
            history.replace({
              pathname: `/map/${facility.id}/service-inquiry/${inquiryId}/response`,
              search,
            });
          }}
        >
          Continue
        </Button>
      </div>
    </Dialog>
  );
};
