import React, { useState, useEffect, Fragment } from "react";
import {
  useForm,
  Controller,
  NestedValue,
  UseFormMethods,
} from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import orderBy from "lodash/orderBy";
import qs from "query-string";

import Typography from "@ui-kit/Typography";
import { Input, OutlinedInput } from "@ui-kit/InputFields";
import DatePicker from "@ui-kit/DatePicker";
import { useCss } from "./styles";
import SuccessStep from "@components/SuccessStep";
import Button from "@ui-kit/Button";
import { useDeepDivePanelStore, usePrevious } from "@hooks";
import { useUserStore } from "@store/UserStore";
import { required, email } from "@validators";
import { GENDERS } from "@constants/genders";
import { ROUTES } from "@constants";
import { CHILDREN_API, FACILITY_API, Models } from "@services/api";
import Select from "@ui-kit/Select";
import { getFacilityFullAddress, isFacilityOwner } from "@selectors";
import MultipleSelect from "@ui-kit/MultipleSelect";
import {
  TOPICS,
  ServiceInquryPreferedEnrollmentSchedule,
  Topic,
  TopicsTextMap,
} from "@constants/service-inquiry";
import { goToFormError } from "@helpers/goToFormError";
import { useNotificationStore } from "@store/NotificationStore";
import { Spacer } from "@ui-kit/Spacer";
import { ExistedWarning } from "@pages/DeepDivePanel/ExistedInquiryWarning";
import { SelfInquiryWarning } from "@pages/DeepDivePanel/SelfInquiryWarning";
import { ServerError } from "@models/common";
import { must } from "@utils/must";
import { ShadowDivider } from "@components/ShadowDivider";
import { useQueryClient } from "react-query";
import { QUERIES } from "@constants/queries";
import { expectServerError } from "@helpers/serverError";

type FormFields = {
  childBirthday: string;
  programEnrollmentDay: string;
  name: string;
  parentEmail: string;
  comment: string;
  topic: NestedValue<Topic[]>;
  preferedEnrollmentSchedule: Models.FacilityContactRequestSchedule;
  gender: Models.Gender;
};

const isFieldOfInquiry = (field: string) => {
  return field === "programEnrollmentDay" || field === "childBirthday";
};

export const processServerErrors = (
  errors: ServerError[],
  setError: UseFormMethods["setError"],
): boolean => {
  let errorHandled = false;

  errors.forEach((e) => {
    const paths = e.source.pointer?.split("/");

    if (!paths) {
      return false;
    }

    if (
      paths[1] === "programEnrollmentDay" &&
      e.code === "validation_min_greater_equal_than_required"
    ) {
      setError(paths[1], {
        message: "Date is in the past",
        type: "manual",
      });
      errorHandled = true;
      return true;
    }

    if (isFieldOfInquiry(paths[1])) {
      setError(paths[1], {
        message: e.title,
        type: "manual",
      });
      errorHandled = true;
      return true;
    }
  });

  return errorHandled;
};

const ServiceInqury: React.FC = () => {
  const { search } = useLocation();
  const [, { setNotification, setUnknownErrorNotification }] =
    useNotificationStore();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [{ user }] = useUserStore();
  const prevUser = usePrevious(user);
  const [existingInquiry, setExistingInquiry] = useState<number | null>(null);
  const [showSelfInquiry, setShowSelfInquiry] = useState(false);
  const [{ facility }] = useDeepDivePanelStore();
  const {
    handleSubmit,
    control,
    errors,
    formState,
    setValue,
    trigger,
    watch,
    setError,
  } = useForm<FormFields>({
    shouldUnregister: false,
  });
  const [step, setStep] = useState(1);
  const css = useCss();

  useEffect(() => {
    void (async () => {
      if (user) {
        const { data } = await CHILDREN_API.childrenIndex();

        if (data.data.length > 0) {
          setValue("childBirthday", data.data[0].birthday);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (!prevUser && user) {
      void (async () => {
        const {
          data: { data },
        } = await FACILITY_API.facilityContactRequestsIndex({
          facilityId: facility.id,
          parentUserId: -1,
        });

        if (!data.length) {
          if (isFacilityOwner(facility, user)) {
            setShowSelfInquiry(true);
          } else {
            void sendRequest();
          }
        } else {
          setExistingInquiry(must(data[0].id));
        }
      })();
    }
  }, [user]);

  const checkAuthorization = () => {
    if (!user) {
      history.replace({
        pathname: ROUTES.SIGNUP_FORM,
        search: qs.stringify({
          addressCountry: facility.address.country,
        }),
      });
    } else {
      void sendRequest();
    }
  };

  const submit = () => {
    void trigger().then((isValid) => {
      if (isValid) {
        checkAuthorization();
      } else {
        goToFormError();
      }
    });
  };

  const sendRequest = handleSubmit(
    async ({
      parentEmail,
      name,
      childBirthday,
      programEnrollmentDay,
      preferedEnrollmentSchedule,
      gender,
      comment,
    }) => {
      try {
        await FACILITY_API.facilityContactRequestsCreate({
          facilityContactRequest: {
            childBirthday,
            childGender: gender,
            childHealthIssues: [],
            childTemperament: [],
            comment,
            facilityId: facility.id.toString(),
            parentEmail,
            parentFirstName: name.split(" ")[0],
            parentLastName: name.split(" ")[1] || "",
            programEnrollmentDay,
            programEnrollmentSchedule: preferedEnrollmentSchedule || null,
            programPreferredPaymentMethods: [],
            programServiceWishList: [],
          },
        });

        void queryClient.invalidateQueries(QUERIES.USER_CHATS);
        void queryClient.invalidateQueries(QUERIES.CHAT_FACILITIES);
        setStep(2);
      } catch (e) {
        const errors = expectServerError(e, setUnknownErrorNotification);

        if (!processServerErrors(errors, setError)) {
          setNotification({
            message: errors[0].title,
            type: "error",
          });
        }
      }
    },
  );

  const onClose = () => {
    history.replace({
      pathname: `/map/${facility.id}`,
      search,
    });
  };

  return (
    <div css={css.container}>
      {!!existingInquiry && (
        <ExistedWarning
          onClose={() => setExistingInquiry(null)}
          inquiryId={existingInquiry}
        />
      )}
      {showSelfInquiry && (
        <SelfInquiryWarning
          onClose={() => setShowSelfInquiry(false)}
          onSend={() => {
            setShowSelfInquiry(false);
            void sendRequest();
          }}
        />
      )}
      {step === 2 && (
        <SuccessStep
          text="Submitted"
          content={
            <Fragment>
              <Typography align="center">
                Facility will be in-touch shortly
              </Typography>
              <ShadowDivider />
              <div css={css.doneAction}>
                <Button
                  variant="contained"
                  color="primary"
                  data-test="done-button"
                  size="large"
                  onClick={onClose}
                >
                  Done
                </Button>
              </div>
            </Fragment>
          }
        />
      )}

      {step === 1 && (
        <Fragment>
          <Typography bolded align="center" gutterBottom>
            {facility.name}
          </Typography>
          <Typography align="center">
            {getFacilityFullAddress(facility)}
          </Typography>

          <Spacer size="medium" />

          <Typography variant="h5" align="center" css={css.sectionTitle}>
            Select questions of interest
          </Typography>

          <Controller
            render={({ onChange, ...rest }) => (
              <MultipleSelect
                {...rest}
                items={TOPICS}
                itemLabelProp="name"
                label="Select up to 3 topics"
                renderValue={(selected) =>
                  orderBy(selected, "order")
                    .map((s) => s.name)
                    .join(", ")
                }
                data-test="topic-select"
                error={errors.topic?.message}
                onChange={(event) => {
                  const { value } = event.target;

                  if (value.length > 3) {
                    return;
                  }

                  setValue(
                    "comment",
                    "Hello! We would like to learn more about your school:\n\n" +
                      orderBy(value, "order")
                        .map((t) => `${TopicsTextMap[t.id]}`)
                        .join("\n\n"),
                  );

                  onChange(event);
                }}
              />
            )}
            rules={{
              validate: (value: Topic[]) => {
                if (!value.length) {
                  return required().required;
                }
                return true;
              },
            }}
            defaultValue={[]}
            name="topic"
            control={control}
          />

          {!!watch("topic")?.length && (
            <Controller
              render={(controllerProps) => (
                <OutlinedInput
                  {...controllerProps}
                  multiline
                  placeholder="Questions of interest"
                  rowsMax="6"
                  maxChars={750}
                  error={errors.comment?.message}
                />
              )}
              rules={required()}
              defaultValue=""
              name="comment"
              control={control}
            />
          )}

          <Spacer size="medium" />

          <Typography variant="h5" align="center" css={css.sectionTitle}>
            Family profile
          </Typography>

          <Controller
            render={(rest) => (
              <Input
                {...rest}
                fullWidth
                error={errors.name?.message}
                label="Parent name"
              />
            )}
            rules={required()}
            name="name"
            defaultValue={user ? `${user.firstName} ${user.lastName}` : ""}
            control={control}
          />

          <Controller
            render={(rest) => (
              <Input
                {...rest}
                fullWidth
                error={errors.parentEmail?.message}
                data-test="email-field"
                label="Parent email"
              />
            )}
            rules={email()}
            name="parentEmail"
            defaultValue={user?.email || ""}
            control={control}
          />

          <Spacer size="medium" />

          <Controller
            render={(controllerProps) => (
              <DatePicker
                {...controllerProps}
                fullWidth
                error={errors.childBirthday?.message}
                data-test="birthday-field"
                label="Child's birthday"
                variant="date"
              />
            )}
            rules={required()}
            name="childBirthday"
            defaultValue=""
            control={control}
          />

          <Controller
            render={(rest) => (
              <Select
                {...rest}
                items={GENDERS}
                labelProp="shortTitle"
                error={errors.gender?.message}
                label="Gender"
              />
            )}
            rules={required()}
            name="gender"
            defaultValue=""
            control={control}
          />

          <Controller
            render={(controllerProps) => (
              <DatePicker
                {...controllerProps}
                fullWidth
                error={errors.programEnrollmentDay?.message}
                data-test="target-date-field"
                label="Target enrollment date"
                disablePast
                variant="date"
              />
            )}
            rules={required()}
            name="programEnrollmentDay"
            defaultValue=""
            control={control}
          />

          <Controller
            render={(rest) => (
              <Select
                {...rest}
                items={ServiceInquryPreferedEnrollmentSchedule}
                labelProp="name"
                error={errors.preferedEnrollmentSchedule?.message}
                label="Preferred enrollment schedule"
              />
            )}
            name="preferedEnrollmentSchedule"
            defaultValue=""
            control={control}
          />

          <Spacer size="medium" />

          <Button
            variant="contained"
            color={
              facility.subscriptionTier === Models.SubscriptionTier.Ivy
                ? "secondary"
                : "black"
            }
            size="large"
            onClick={submit}
            fullWidth
            data-test="submit-button"
            css={css.sendButton}
            loading={formState.isSubmitting || formState.isSubmitSuccessful}
          >
            Send
          </Button>
        </Fragment>
      )}
    </div>
  );
};

export default ServiceInqury;
