import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const useCss = (): Record<Classes, Interpolation<ITheme>> => ({
  container: (theme) => ({
    minHeight: 100,
    position: "relative",

    [theme.mediaRequests.notMobile]: {
      width: 400,
    },
  }),

  sendButton: {
    marginTop: 30,
  },

  doneAction: {
    display: "flex",
    justifyContent: "center",
    marginTop: 45,

    "& button": {
      minWidth: 100,
    },
  },

  sectionTitle: {
    backgroundColor: "rgba(0, 0, 0, .06)",
    padding: 7,
  },
});
