import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import Dialog from "@ui-kit/Dialog";
import ServiceInquiry from "@components/ServiceInquiry";
import { useDeepDivePanelStore } from "@hooks";
import { useUserStore } from "@store/UserStore";
import { QuitDialog } from "@components/QuitDialog";
import { FACILITY_API } from "@services/api";

const ServiceInquiryPage: React.FC = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [{ facility }] = useDeepDivePanelStore();
  const [{ user }] = useUserStore();
  const [inited, setInited] = useState(false);
  const [quit, setQuit] = useState(false);

  const onClose = () => {
    history.replace({
      pathname: `/map/${facility.id}`,
      search,
    });
  };

  const getExistingInquiry = useCallback(async () => {
    const {
      data: { data },
    } = await FACILITY_API.facilityContactRequestsIndex({
      facilityId: facility.id,
      parentUserId: -1,
    });

    if (!data.length) {
      setInited(true);
      return;
    }

    history.replace({
      pathname: `/map/${facility.id}/service-inquiry/${data[0].id}/response`,
      search,
    });
  }, [user, facility]);

  useEffect(() => {
    if (!user) {
      setInited(true);
      return;
    }

    void getExistingInquiry();
  }, []);

  if (!inited) {
    return null;
  }

  return (
    <Dialog
      open
      onClose={() => {
        setQuit(true);
      }}
      data-test="enrollment-inquiry-dialog"
      tier={facility.subscriptionTier}
      title="Enrollment inquiry"
    >
      {quit && (
        <QuitDialog
          onQuit={onClose}
          onClose={() => setQuit(false)}
          tier={facility.subscriptionTier}
        />
      )}
      <ServiceInquiry />
    </Dialog>
  );
};

export default ServiceInquiryPage;
