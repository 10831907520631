import React from "react";

import Dialog from "@ui-kit/Dialog";
import Typography from "@ui-kit/Typography";
import { createCss } from "./styles";
import Button from "@ui-kit/Button";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";
import { Spacer } from "@ui-kit/Spacer";
import { Models } from "@services/api";

export const SelfInquiryWarning: React.FC<{
  onClose: () => void;
  onSend: () => void;
}> = ({ onClose, onSend }) => {
  const [{ facility }] = useDeepDivePanelStore();
  const css = createCss();

  return (
    <Dialog open onClose={onClose} tier={facility.subscriptionTier}>
      <div css={css.root}>
        <Typography align="center" variant="h5" bolded paragraph>
          You have previously claimed this school.
        </Typography>
        <Typography align="center" variant="h5" bolded>
          Send message anyway?
        </Typography>
        <Spacer size="medium" />
        <div css={css.actions}>
          <Button
            variant="outlined"
            color={
              facility.subscriptionTier === Models.SubscriptionTier.Ivy
                ? "secondary"
                : "black"
            }
            size="large"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color={
              facility.subscriptionTier === Models.SubscriptionTier.Ivy
                ? "secondary"
                : "black"
            }
            size="large"
            onClick={onSend}
          >
            Send
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
