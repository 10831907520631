import { Models } from "@services/api";

export interface Topic {
  id: TopicID;
  name: string;
  order: number;
}

enum TopicID {
  WaitlistAndAvailability = "waitlistAndAvailability",
  AgesAndGradesServed = "agesAndGradesServed",
  OperatingHours = "operatingHours",
  DropOffAndPickUpPolicy = "dropOffAndPickUpPolicy",
  DailySchedule = "dailySchedule",
  Curriculum = "curriculum",
  TuitionAndFeesSchedule = "tuitionAndFeesSchedule",
  MealPlanOptions = "mealPlanOptions",
  PottyTrainingGuidelines = "pottyTrainingGuidelines",
  MaskPolicy = "maskPolicy",
  PdoService = "pdoService",
  SchoolOpenHouse = "schoolOpenHouse",
  EnrollmentTrial = "enrollmentTrial",
  AfterSchoolProgram = "afterSchoolProgram",
  SummerCamp = "summerCamp",
}

export const TOPICS: Topic[] = [
  {
    id: TopicID.SchoolOpenHouse,
    name: "School open house",
  },
  {
    id: TopicID.EnrollmentTrial,
    name: "Enrollment trial",
  },
  {
    id: TopicID.WaitlistAndAvailability,
    name: "Availability & waitlist",
  },
  {
    id: TopicID.AgesAndGradesServed,
    name: "Ages & grades served",
  },
  {
    id: TopicID.OperatingHours,
    name: "Operating hours",
  },
  {
    id: TopicID.DropOffAndPickUpPolicy,
    name: "Drop-off & pick-up policy",
  },
  {
    id: TopicID.DailySchedule,
    name: "Daily schedule",
  },
  {
    id: TopicID.Curriculum,
    name: "Curriculum",
  },
  {
    id: TopicID.TuitionAndFeesSchedule,
    name: "Tuition & fees information",
  },
  {
    id: TopicID.MealPlanOptions,
    name: "Meals & meal plans",
  },
  {
    id: TopicID.PottyTrainingGuidelines,
    name: "Potty training guidelines",
  },
  {
    id: TopicID.MaskPolicy,
    name: "Mask policy",
  },
  {
    id: TopicID.PdoService,
    name: "Parent Day Out experience",
  },
  {
    id: TopicID.AfterSchoolProgram,
    name: "After-school program",
  },
  {
    id: TopicID.SummerCamp,
    name: "Summer camp",
  },
].map((t, i) => ({ ...t, order: i }));

export interface TourPreference {
  id: Models.FacilityContactRequestTourPreference;
  name: string;
}

export const ServiceInqurySchoolTourPreferences = [
  {
    id: Models.FacilityContactRequestTourPreference.OnDemandVideoTour,
    name: "Virtual tour (prerecorded)",
  },
  {
    id: Models.FacilityContactRequestTourPreference.ZoomVideoCall,
    name: "Video call tour",
  },
  {
    id: Models.FacilityContactRequestTourPreference.DaytimeSchoolTour,
    name: "In-person visit",
  },
];

export const ServiceInquryPreferedEnrollmentSchedule = [
  {
    id: Models.FacilityContactRequestSchedule.FullTime,
    name: "Full-time",
  },
  {
    id: Models.FacilityContactRequestSchedule.PartTime,
    name: "Part-time",
  },
  {
    id: Models.FacilityContactRequestSchedule._5daysPerWeek,
    name: "5 days per week",
  },
  {
    id: Models.FacilityContactRequestSchedule._3daysPerWeek,
    name: "3 days per week",
  },
  {
    id: Models.FacilityContactRequestSchedule._2daysPerWeek,
    name: "2 days per week",
  },
  {
    id: Models.FacilityContactRequestSchedule.MonWedFri,
    name: "Mon Wed Fri",
  },
  {
    id: Models.FacilityContactRequestSchedule.TuesThurs,
    name: "Tues Thurs",
  },
];

export const TopicsTextMap: {
  [key in TopicID]: string;
} = {
  [TopicID.WaitlistAndAvailability]:
    "For my child’s grade, is there currently a waitlist? If yes, how long approximately?",
  [TopicID.OperatingHours]:
    "What are your operating hours? What time is drop-off and pick-up?",
  [TopicID.Curriculum]: "What curriculum methodology do you follow?",
  [TopicID.PdoService]:
    "For enrolled families, do you offer a Parent Date Night / Parent Day Out service? " +
    "How frequently are such events held and how far in advance can reservations be made?",
  [TopicID.TuitionAndFeesSchedule]:
    "For full-time enrollment, what would the tuition be?",
  [TopicID.AgesAndGradesServed]:
    "What ages/grades do you serve? Is there currently an opening for my child?",
  [TopicID.DailySchedule]:
    "For my child's classroom, what does a typical school day look like?",
  [TopicID.MealPlanOptions]:
    "Are meals from home permitted? Does the school offer a meal plan?",
  [TopicID.PottyTrainingGuidelines]:
    "By what age are students required to be potty trained?",
  [TopicID.DropOffAndPickUpPolicy]:
    "What is your drop off and pick-up policy? Is it curbside?",
  [TopicID.MaskPolicy]:
    "Are students required to wear face masks? If yes, starting at what age?",
  [TopicID.SchoolOpenHouse]:
    "We are interested in attending a school open house. When will the next one be held?",
  [TopicID.EnrollmentTrial]:
    "For new students, do you offer an enrollment trial? If yes, how long is the trial period?",
  [TopicID.AfterSchoolProgram]:
    "Do you offer an after-school program? If yes, what time is " +
    "drop-off / pick-up and what are the enrollment options?",
  [TopicID.SummerCamp]:
    "Do you host a summer camp? If yes, how long is the camp?",
};

export const TourPreferenceTextMap: {
  [key in Models.FacilityContactRequestTourPreference]: string;
} = {
  [Models.FacilityContactRequestTourPreference.OnDemandVideoTour]:
    "Do you offer virtual school tours (prerecorded)?  If yes, please share a link.  If no, what school tour options are currently available?",
  [Models.FacilityContactRequestTourPreference.ZoomVideoCall]:
    "Is there an option to do a school tour via a video call? If yes, when is the best time to schedule one?",
  [Models.FacilityContactRequestTourPreference.DaytimeSchoolTour]:
    "Are you currently conducting school tours?  If yes, when would be the best time to stop by (day/time)?",
};
